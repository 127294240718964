"use client";
import React, { useState } from "react";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-item ${isOpen ? "active" : ""}`}>
      <div className="accordion-item__header" onClick={toggleAccordion}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="que">{title}</h4>
          {isOpen ? (
            <div className="icon-box-wrapper">
              <div className={`icon-box ${isOpen ? "active" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke={isOpen ? "#fff" : "#4A3AFF"}
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div className="icon-box-wrapper">
              <div className={`icon-box ${isOpen ? "active" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke={isOpen ? "#fff" : "#4A3AFF"}
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <p className="ans">{content}</p>
        </div>
      )}
    </div>
  );
};

const FaqAccordion = ({ items }) => {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default FaqAccordion;
