import method1 from "./assets/images/home/m1.png";
import method2 from "./assets/images/home/method2.png";
import method3 from "./assets/images/home/method3.png";
import method4 from "./assets/images/home/method4.png";

import kapil from "./assets/images/home/kapil.webp";
import bharti from "./assets/images/home/bharti.webp";
import chavi from "./assets/images/home/chavi.webp";

import stats1 from "./assets/images/stats1.png";
import stats2 from "./assets/images/stats2.png";
import stats3 from "./assets/images/stats3.png";
import stats4 from "./assets/images/stats4.png";

import abhishek from "./assets/images/home/abhishek.webp";
import gagandeep from "./assets/images/home/gagandeep.webp";

import gameApartLogo from "./assets/images/common/color-heylime.webp";
import mystakeLogo from "./assets/images/common/color-mystake.webp";
import wishhealthLogo from "./assets/images/common/color-wishhealth.webp";
import foodclubLogo from "./assets/images/common/color-foodclub.webp";
import bonfireLogo from "./assets/images/common/color-bonfire.webp";
import block8Logo from "./assets/images/common/color-block8.webp";
import meritLogo from "./assets/images/common/color-merit.webp";
import fantomLogo from "./assets/images/common/color-fantom.webp";

import clientLogo1 from "./assets/images/common/my-stake.webp";
import colorClientLogo1 from "./assets/images/common/color-mystake.webp";
import clientLogo2 from "./assets/images/common/heylime-black.svg";
import colorClientLogo2 from "./assets/images/common/color-heylime.webp";
import clientLogo3 from "./assets/images/common/bonfire.webp";
import colorClientLogo3 from "./assets/images/common/color-bonfire.webp";
import clientLogo4 from "./assets/images/common/wish-health.png";
import colorClientLogo4 from "./assets/images/common/color-wishhealth.webp";
import clientLogo5 from "./assets/images/common/crypto-flip.png";
import colorClientLogo5 from "./assets/images/common/color-cryptoflip.webp";
import clientLogo6 from "./assets/images/common/foodclub-black.webp";
import colorClientLogo6 from "./assets/images/common/color-foodclub.webp";
import clientLogo7 from "./assets/images/common/merit.webp";
import colorClientLogo7 from "./assets/images/common/color-merit.webp";
import clientLogo8 from "./assets/images/common/fantom.png";
import colorClientLogo8 from "./assets/images/common/color-fantom.webp";
import clientLogo9 from "./assets/images/common/block-8.webp";
import colorClientLogo9 from "./assets/images/common/color-block8.webp";

import foodclub from "./assets/images/home/foodclub.webp";
import heylime from "./assets/images/home/heylime.webp";
import bonfire from "./assets/images/home/bonfire.webp";

import logo1 from "./assets/images/logo/woo.png";
import logo2 from "./assets/images/logo/dotnet.png";
import logo3 from "./assets/images/logo/c-sharp.png";
import logo4 from "./assets/images/logo/mysql.png";
import logo5 from "./assets/images/logo/angular.png";
import logo6 from "./assets/images/logo/react.png";
import logo7 from "./assets/images/logo/html.png";
import logo8 from "./assets/images/logo/css.png";
import logo9 from "./assets/images/logo/node.png";
import logo10 from "./assets/images/logo/python.png";
import logo11 from "./assets/images/logo/react-native.png";
import logo12 from "./assets/images/logo/flutter.png";
import logo13 from "./assets/images/logo/illustrator.png";
import logo14 from "./assets/images/logo/adobeXD.png";
import logo15 from "./assets/images/logo/figma.png";
import logo16 from "./assets/images/logo/photoshop.png";

import app from "./assets/images/home/1.png";

export const ourMethodology = [
  {
    id: 1,
    img: method1,
    title: "Strategic Project Assessment",
    para: "We grasp your project's goals, collaborate with you to set a clear vision, and lay the foundation for successful development.",
  },
  {
    id: 2,
    img: method2,
    title: "Tailored Development Approach",
    para: "Tailored strategies for your unique projects, ensuring precise alignment with your goals",
  },
  {
    id: 3,
    img: method3,
    title: "Agile Iterations and Collaboration",
    para: "Agile approach keeps you involved. Iterative development and regular feedback integrate seamlessly.",
  },
  {
    id: 4,
    img: method4,
    title: "Quality Assurance and Support",
    para: "Quality is key. Rigorous testing for bug-free products. Post-deployment, comprehensive support ensures ongoing success.",
  },
];

export const clientsLogo = [
  {
    id: 1,
    img: clientLogo8,
    alt: "fantom logo",
    hoverImg: colorClientLogo8,
  },
  {
    id: 2,
    img: clientLogo9,
    alt: "Block8 logo",
    hoverImg: colorClientLogo9,
  },
  {
    id: 3,
    img: clientLogo7,
    alt: "Merit logo",
    hoverImg: colorClientLogo7,
  },
  {
    id: 4,
    img: clientLogo2,
    alt: "heylime logo",
    hoverImg: colorClientLogo2,
  },
  {
    id: 5,
    img: clientLogo1,
    alt: "mystake logo",
    hoverImg: colorClientLogo1,
  },
  {
    id: 6,
    img: clientLogo3,
    alt: "bonfire logo",
    hoverImg: colorClientLogo3,
  },
  {
    id: 7,
    img: clientLogo4,
    alt: "wishhealth logo",
    hoverImg: colorClientLogo4,
  },
  // {
  //   id: 8,
  //   img: clientLogo5,
  //   alt: "cryptoflip logo",
  //   hoverImg: colorClientLogo5,
  // },
  {
    id: 9,
    img: clientLogo6,
    alt: "foodclub logo",
    hoverImg: colorClientLogo6,
  },
];

export const techStackArr = [
  {
    id: 1,
    img: logo1,
  },
  {
    id: 2,
    img: logo2,
  },
  {
    id: 3,
    img: logo3,
  },
  {
    id: 4,
    img: logo4,
  },
  {
    id: 5,
    img: logo5,
  },
  {
    id: 6,
    img: logo6,
  },
  {
    id: 7,
    img: logo7,
  },
  {
    id: 8,
    img: logo8,
  },
  {
    id: 9,
    img: logo9,
  },
  {
    id: 10,
    img: logo10,
  },
  {
    id: 11,
    img: logo11,
  },
  {
    id: 12,
    img: logo12,
  },
  {
    id: 13,
    img: logo13,
  },
  {
    id: 14,
    img: logo14,
  },
  {
    id: 15,
    img: logo15,
  },
  {
    id: 16,
    img: logo16,
  },
];

export const caseStudies = [
  {
    id: 1,
    img: foodclub,
    title: "FoodClub - Food Ordering App",
    info: "We developed FoodClub, a virtual platform to make food ordering a breeze. With FoodClub, customers can explore a diverse range of top-rated dishes from different restaurants, all conveniently presented in a single menu. What sets it apart is that customers can place multiple orders at the same time, making the whole experience incredibly convenient.",
    url: "https://innow8apps.com/casestudy/foodclub/",
  },
  {
    id: 2,
    img: heylime,
    title: "Heylime - Gaming App",
    info: "Our vision was to introduce a platform that adds enjoyment to video calls, no matter the conferencing tool. That's how heyLime was born. It lets friends, family, and coworkers keep the closeness and personal touch of playing games collectively, regardless of their locations and schedules.",
    url: "https://innow8apps.com/casestudy/heylime/",
  },
  {
    id: 3,
    img: bonfire,
    title: "Bonfire - Virtual Gathering App",
    info: "The idea was to launch a platform that brings people together through virtual video gatherings. That's when we developed Bonfire. With Bonfire, users can arrange virtual get-togethers, inviting their loved ones to join and recreate the in-person experience, no matter where they are.",
    url: "https://innow8apps.com/casestudy/bonfire/",
  },
];

export const clientsArr = [
  {
    id: 1,
    img: gameApartLogo,
  },
  {
    id: 2,
    img: mystakeLogo,
  },
  {
    id: 3,
    img: wishhealthLogo,
  },
  {
    id: 4,
    img: foodclubLogo,
  },
  {
    id: 5,
    img: block8Logo,
  },
  {
    id: 6,
    img: meritLogo,
  },
  {
    id: 7,
    img: fantomLogo,
  },
];

export const ourTeam = [
  {
    id: 1,
    img: chavi,
    name: "Chavi Jindal",
    position: "Founder & CEO",
    desc: "With 11+ years in web, app, and software realms, Chavi is the Founder & CEO of Innow8 Apps. He's curated a dynamic team of experts, dedicated to shaping the future today, propelling innovation at every step.",
  },
  {
    id: 2,
    img: kapil,
    name: "Kapil Bindal",
    position: "Founder & CEO",
    desc: "Meet Kapil Bindal,the visionary Founder behind Innow8 Apps. With 8+ years of expertise, he's a serial entrepreneur and innovation advocate. His commitment to integrating technology and process standards, including AI automation, has fueled growth and triumph across all his leadership ventures.",
  },
  {
    id: 3,
    img: bharti,
    name: "Bharti Dhawan",
    position: "Business Development",
    desc: "With 6+ years in business development, Bharti drives Innow8 Apps. Her passion for fostering relationships, revenue growth, and strategic thinking sets the stage for dynamic success.",
  },
];

export const statistics = [
  {
    id: 1,
    img: stats1,
    num: "300+",
    name: "Worldwide clients",
  },
  {
    id: 2,
    img: stats2,
    num: "10+",
    name: "Years of experience",
  },
  {
    id: 3,
    img: stats3,
    num: "150+",
    name: "Mobile app developers",
  },
  {
    id: 4,
    img: stats4,
    num: "100%",
    name: "Success score on upwork",
  },
];

export const faqItems = [
  {
    id: 1,
    title: "What services does Innow8 Apps provide in the UK?",
    content:
      "Innow8 Apps offers a range of services including custom software development, mobile app development, web design, blockchain solutions, and more.",
  },
  {
    id: 2,
    title: "How can I get started with a project with Innow8 Apps?",
    content:
      "To start a project with us, simply reach out through our contact page, provide your project details, and our team will get in touch to discuss your requirements and next steps.",
  },
  {
    id: 3,
    title: "What industries does Innow8 Apps cater to?",
    content:
      "We serve diverse industries including finance, healthcare, e-commerce, entertainment, and more, providing tailored solutions to meet specific business needs.",
  },
  {
    id: 4,
    title:
      "How does Innow8 Apps ensure the security of its software solutions?",
    content:
      "We prioritize security throughout our development process, utilizing industry best practices and technologies to create secure and reliable software solutions.",
  },
  {
    id: 5,
    title: "Does Innow8 Apps offer ongoing support and maintenance for developed applications?",
    content:
      "Yes, we provide post-launch support and maintenance services to ensure your application continues to function optimally and stays up-to-date with evolving technologies.",
  },
];

export const advisoryBoard = [
  {
    id: 1,
    img: abhishek,
    name: 'Abhishek Kandivalikar',
    exp: '15+ years',
    position: 'Chief Technology Advisor',
    description: 'Over 15 years of extensive involvement in the IT field powers his expertise. As Chief Technology Advisor, Abhishek brings his technical prowess to Wish Health. He has designed several platforms for banks.'
  },
  {
    id: 2,
    img: gagandeep,
    name: 'Gagan Deep Singh',
    exp: '20+ years',
    // position: 'Sr. System Platform Engineer',
    position: 'Chief Marketing Advisor',
    description: 'Gagan is a dynamic businessman, serial entrepreneur & IT executive who co-founded few startups. Gagan as Cheif Marketing Advisor brings 20 years of viable global IT experience along with working with worlds best brands in IT(Infosys, TechM, Birlasoft, GE) & Fintech(MasterCard, NatWest, BP) to the table.'
  },
]

export const contactDetails = [
  {
    id: 1,
    country: "UK",
    phone: "+44 781 888 0974",
    phoneLink: "+447818880974",
    email: "bbhatia@innow8apps.com",
    emailLink: "bbhatia@innow8apps.com",
    address: "12 leam close, Colchester, CO4 3TE, UK",
  },
  {
    id: 1,
    country: "India",
    phone: "+91 9888886602",
    phone2: "+919915376280",
    phoneLink: "+919888886602",
    email: "contact@innow8apps.com",
    emailLink: "bbhatia@innow8apps.com",
    address:
      "910B Bestech Business Tower Sector 66 ,Mohali , Punjab India, 160055",
  },
  {
    id: 1,
    country: "Canada",
    phone: "+1 (604) 374-5278",
    phoneLink: "+16043745278",
    email: "contact@whitenappsolutions.com",
    emailLink: "contact@whitenappsolutions.com",
    address:
      "Whiten App Solutions Inc. 422 Richards St unit 170, Vancouver, BC V6B 2Z4, Canada",
  },
];
