import indiaFlag from "./assets/images/common/india-flag.png";

export const NAV_MENU = [
  {
    name: "Services",
    url: "#services",
  },
  {
    name: "Case Studies",
    url: "#case-studies",
  },
  {
    name: "Methodology",
    url: "#methodology",
  },
  {
    name: "Our Team",
    url: "#our-team",
  },
  {
    name: "Tech Stack",
    url: "#tech-stack",
  },
  {
    name: "Contact Us",
    url: "#contact-us",
    contactBtn: "contact-btn",
  },
  {
    name: "Visit innow8.com",
    url: "https://innow8apps.com/",
    visitBtn: "visit-btn",
    flag: indiaFlag,
  },
];

export const CONTACT_FORM_STEPS = [
  "Fill out our contact form with your details and project requirements.",
  "Our team will promptly review your inquiry and get in touch to schedule a consultation.",
  "Following the consultation, we'll create a customized proposal",
  "Once you're satisfied with the proposal, we initiate the project.",
];