import React from "react";
import Slider from "react-slick";
import quoteImg from "../../assets/images/common/quote.png";
import quoteImg2 from "../../assets/images/common/quote2.png";
import starImg from "../../assets/images/common/star.png";
import { baseUrl, starUrl, testimonialsContent } from "./config";

function TestimonialNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

function TestimonialPrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} onClick={onClick} />;
}

const clientsData = [
  {
    id: 1,
    img: [starImg, starImg, starImg, starImg, starImg],
    name: "Tim Bass",
    profile: "CEO, Block8",
  },
  {
    id: 2,
    img: [starImg, starImg, starImg, starImg, starImg],
    name: "Gaurav Bikram",
    profile: "CEO, Wish Health Pvt Ltd",
  },
  {
    id: 3,
    img: [starImg, starImg, starImg, starImg, starImg],
    name: "Michael Kong",
    profile: "CTO, DCH",
  },
  {
    id: 4,
    img: [starImg, starImg, starImg, starImg, starImg],
    name: "Max Kenny",
    profile: "CEO, Crypto Gaming Australia",
  },
  {
    id: 5,
    img: [starImg, starImg, starImg, starImg, starImg],
    name: "Matt Mills",
    profile: "CEO, Mystake",
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
    nextArrow: <TestimonialNextArrow />,
    prevArrow: <TestimonialPrevArrow />,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      const currentItem = clientsData[i];
      return (
        <a>
          <img
            className="client-img"
            src={`/${baseUrl}/client${i + 1}.webp`}
            width={80}
            height={80}
            alt=""
          />
          <div style={{ display: "flex" }}>
            {currentItem.img.map((image) => {
              return (
                <img
                  key={image}
                  src={image}
                  width={18}
                  height={18}
                  className="star-img"
                  alt=""
                />
              );
            })}
          </div>
          <h4 className="name">{currentItem.name}</h4>
          <p className="profile">{currentItem.profile}</p>
        </a>
      );
    },
  };
  return (
    <section className="testimonials">
      <div className="container">
        <h2 className="title text-center">
          Client Testimonials That Fuel Our Passion
        </h2>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            <Slider {...settings}>
              {testimonialsContent.map((item) => {
                return (
                  <div className="content" key={item.id}>
                    <p>
                      <img
                        width={40}
                        height={40}
                        src={item.img}
                        className="quote-img"
                        alt=""
                      />
                      {item.content}
                      <img
                        width={40}
                        height={40}
                        src={item.img2}
                        className="quote-img2"
                        alt=""
                      />
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
