
import quoteImg from "../../assets/images/common/quote.png";
import quoteImg2 from "../../assets/images/common/quote2.png";

export const baseUrl = "../../../images";
export const starUrl = "../../../images/star.png";
// export const baseUrl = "../../../assets/images";

export const testimonialsContent = [
  {
    id: 1,
    img: quoteImg,
    img2: quoteImg2,
    content: 'We’ve been working with the Innow8 team for 18 months and they have consistently produced high-quality work. They always deliver on time and on budget. We highly recommend them for your web development needs.'
  },
  {
    id: 2,
    img: quoteImg,
    img2: quoteImg2,
    content: 'The collaboration with Innow8 worked out perfectly for us. Innow8 was able to fulfill the client\'s requirements. Their vast experience and wealth of knowledge allowed them to suggest and deliver a system that surpasses the company\'s existing solution.'
  },
  {
    id: 3,
    img: quoteImg,
    img2: quoteImg2,
    content: 'The team at Innow8 has been outstanding. They have been extremely responsive, asked insightful questions and proposed solutions to difficult problems. The work has always been completed to a high standard on time.'
  },
  {
    id: 4,
    img: quoteImg,
    img2: quoteImg2,
    content: 'The Innow8 team have never ceased to amaze me in how skilled, hard working and dedicated they are in their work. There is never a task they cannot complete and they’re always willing to go the extra mile. I look forward to continuing to work with them in the future.'
  },
  {
    id: 5,
    img: quoteImg,
    img2: quoteImg2,
    content: 'Innow8 are a fantastic team who always strive to deliver to the best of their ability. Their service, commitment, and quality are why myStake chooses them.'
  },
]
