import { NAV_MENU } from "../../utils"
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/common/logo.png";
import indiaFlag from "../../assets/images/common/india-flag.png";
import { Link } from "react-router-dom";
// import getWindowSize from "../../getWindowSize";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    console.log(mobileMenu, "mobileMenu");
  };

  const  handleClick = ()=>{
    setMobileMenu(false)
  }
  return (
    <header className="header fixed-top">
      <div className="container">
        <nav className="header-menu">
          <a href="/"><img src={logo} alt="" /></a>
          <ul>
            {NAV_MENU.map((menu, index) => {
              return (
                <li key={menu.name}>
                  <a
                    href={menu.url}
                    className={
                      index === 5
                        ? menu.contactBtn
                        : index === 6
                        ? menu.visitBtn
                        : ""
                    }
                    target={index === 6 && "_blank"}
                  >
                    {menu.name}
                    {index === 6 && (
                      <img width={22} height={22} src={menu.flag} alt="" />
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <nav className="mobile-menu">
          <img src={logo} alt="" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
            onClick={handleMobileMenu}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </nav>
        {mobileMenu && (
          <ul className="mobile-menu-dropdown">
            {NAV_MENU.map((menu, index) => {
              return (
                <li key={menu.name}>
                  <a
                    className={
                      index === 5
                        ? menu.contactBtn
                        : index === 6
                        ? menu.visitBtn
                        : ""
                    }
                    onClick={()=>handleClick()}
                    href={menu.url}
                  >
                    {menu.name}
                    {index === 6 && (
                      <img width={22} height={22} src={menu.flag} alt="" />
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
