import logo from "../../assets/images/common/logo-white.png";
import phone from "../../assets/images/common/phone.png";
import email from "../../assets/images/common/email.png";
import address from "../../assets/images/common/address.png";
import { contactDetails } from "../../data"
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-lg-3">
            {/* <img src={logo} alt="logo" /> */}
            <img src={logo} width={193} height={67} alt="logo" />
          </div>
          <div className="col-md-9 col-lg-9">
            <div className="newsletter">
              <h4 className="text">Join Our Newsletter</h4>
              <div className="newsletter-flex">
                <input
                  type="text"
                  name="email"
                  className="input"
                  placeholder="Email Address"
                  required
                />
                <button className="submit-btn">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          {contactDetails.map((item) => {
            return (
              <div className="col-md-4 col-lg-4" key={item.id}>
                <h4 className="country">{item.country}</h4>
                <ul>
                  <li>
                    <img
                      src={phone}
                      width={24}
                      className="icon"
                      height={24}
                      alt="phone"
                    />
                    <a href={`tel:${item.phoneLink}`}>{item.phone}</a>
                    {","}
                    <a href={`tel:${item.phone2}`} className="ml-4">
                      {item.phone2}
                    </a>
                  </li>
                  <li>
                    <img
                      src={email}
                      width={24}
                      className="icon"
                      height={24}
                      alt="email"
                    />
                    <a href={`mailto:${item.emailLink}`}>{item.email}</a>
                  </li>
                  <li>
                    <img
                      src={address}
                      width={24}
                      height={24}
                      className="icon"
                      alt="address"
                    />
                    {item.address}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
